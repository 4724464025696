import React from "react";

import "twin.macro";

import Layout from "../components/Layout";
import SEO from "../components/SEO";
import FixedContainer from "../components/FixedContainer";

const NotFoundPage = () => (
  <Layout>
    <SEO title="404" />
    <FixedContainer tw="mt-4 bg-white shadow p-2">
      <h1 tw="text-2xl font-bold mb-2">404 Not Found</h1>
      <p>
        You just hit a route that doesn&#39;t exist... go for a bike ride
        instead?
      </p>
    </FixedContainer>
  </Layout>
);

export default NotFoundPage;
